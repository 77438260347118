import Routes from '../../constants/routes';

const navLinks = [
  {
    title: 'Home',
    key: 'Home',
    isExternal: false,
    value: '/'
  },
  {
    title: 'Bulk Order Enquiry',
    key: 'Bulk Order',
    isExternal: false,
    value: Routes.BULK_ORDERS
  },
  {
    title: 'Project Assistance',
    key: 'Project Assistance',
    isExternal: true,
    value: 'https://yuvipep.com/project-assistance'
  },
  {
    title: 'Blogs',
    key: 'Blogs',
    isExternal: true,
    value: 'https://yuvipep.com/blogs'
  },
  {
    title: 'Contact Us',
    key: 'Contact us',
    isExternal: false,
    value: Routes.CONTACT_US
  },
  {
    title: 'FAQ',
    key: 'FAQ',
    isExternal: false,
    value: Routes.FAQ
  }
];
export default navLinks;
